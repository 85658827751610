import Vue from 'vue'
import VueRouter from 'vue-router'
import PrivacyView from '@/views/mobile/PrivacyView'
// import BaseLayout from '@/views/V1/BaseLayout'
import NewLayout from '@/views/V3/NewLayout'
import TopLayout from '@/views/V3/TopLayout'


// hack router push callback
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: NewLayout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/V3/IndexView.vue')
      },
      {
        path: '/create',
        name: 'create',
        component: () => import('@/views/V3/CreateView.vue')
      },
      {
        path: '/product',
        name: 'product',
        component: () => import('@/views/V3/ProductView.vue')
      }
    ]
  },
  {
    path: '/agreement',
    component: TopLayout,
    redirect: '/agreement',
    children: [
      {
        path: '/agreement',
        name: 'agreement',
        component: () => import('@/views/V3/AgreementView.vue')
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/V3/PrivacyView.vue')
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('@/views/V3/ContactUs.vue')
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('@/views/V3/NewsView.vue')
      },
      {
        path: '/newDetail',
        name: 'newDetail',
        component: () => import('@/views/V3/NewDetailView.vue')
      },
      // {
      //   path: '/recharge',
      //   name: 'recharge',
      //   component: () => import('@/views/V3/RechargeView.vue')
      // },
      {
        path: '/topOfficial',
        name: 'topOfficial',
        component: () => import('@/views/V3/TopOfficialView.vue')
      },
      {
        path: '/topUser',
        name: 'topUser',
        component: () => import('@/views/V3/TopUserView.vue')
      },
      {
        path: '/scenesDetail',
        name: 'scenesDetail',
        component: () => import('@/views/V3/ScenesDetailView.vue')
      },
      {
        path: '/hotClothes',
        name: 'hotClothes',
        component: () => import('@/views/V3/HotClothesView.vue')
      },
      {
        path: '/expertUser',
        name: 'expertUser',
        component: () => import('@/views/V3/ExpertUserView.vue')
      },
      {
        path: '/userDetail',
        name: 'userDetail',
        component: () => import('@/views/V3/UserDetailView.vue')
      },
      {
        path: '/clothesDetail',
        name: 'clothesDetail',
        component: () => import('@/views/V3/ClothesDetailView.vue')
      },
      {
        path: '/talentStory',
        name: 'talentStory',
        component: () => import('@/views/V3/TalentStoryView.vue')
      },
      {
        path: '/talentStoryDetail',
        name: 'talentStoryDetail',
        component: () => import('@/views/V3/TalentStoryDetailView.vue')
      },
      {
        path: '/tutorials',
        name: 'tutorials',
        component: () => import('@/views/V3/TutorialView.vue')
      },
      {
        path: '/article',
        name: 'article',
        component: () => import('@/views/V3/ArticleView.vue')
      },
    ]
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: BaseLayout,
  //   redirect: '/home',
  //   children: [
  //     {
  //       path: '/',
  //       name: 'home',
  //       component: () => import('@/views/V1/HomeView.vue')
  //     },
  //     {
  //       path: '/creator',
  //       name: 'creator',
  //       component: () => import('@/views/V1/CreatorView.vue')
  //     },
  //     {
  //       path: '/download',
  //       name: 'download',
  //       component: () => import('@/views/V1/DownloadView.vue')
  //     },
  //     {
  //       path: '/aboutus',
  //       name: 'aboutus',
  //       component: () => import('@/views/V1/AboutView.vue')
  //     },
  //     {
  //       path: '/agreement',
  //       name: 'agreement',
  //       component: () => import('@/views/V1/AgreementView.vue')
  //     },
  //     {
  //       path: '/privacyPolicy',
  //       name: 'privacy',
  //       component: () => import('@/views/V1/PrivacyView.vue')
  //     }
  //   ]
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
