<template>
  <router-view :key="key"></router-view>
</template>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data () {
    return {}
  },
  computed:{
    ...mapGetters(['staticUrl', 'language']),
    key(){
      return this.$route.name?this.$route.name+ +new Date():this.$route+ +new Date()
    },
  },
  created () {
    document.title = window.documentTitle
  },
}
</script>
