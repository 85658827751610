<template>
  <div class="bg-gradient3">
    <div class="v3footbar-link">
      <div class="left">
        <div class="link-item" v-for="(item,index) in v3footData.menuList" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="link" v-for="(value,key) in item.list" :key="key">
            <a :href="value.url">{{ value.title }}</a>
          </div>
        </div>
      </div>
      <div class="right">
        <div>Follow us</div>
        <a v-for="(item,index) in followUs" :key="index" :href="item.url" target="_blank">
          <img :src="item.img" />
        </a>
      </div>
    </div>
    <div class="v3footbar-copy">
      <div class="line"></div>
      <div class="content">
        <a v-for="(item,index) in v3footData.copyright.list" :key="index" :href="item.url">{{ item.title }}</a>
        {{ v3footData.copyright.copyright }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'footBarV3',
  computed: {
    ...mapGetters(['isMobile', 'v3staticUrl', 'v3footData', 'followUs']),
  },
  methods: {}
}
</script>

<style lang="scss">

</style>
