import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'

Vue.filter('ymday', function (dataStr, pattern = 'YYYY-MM-DD') {
  if (!isNaN(dataStr)) {
    dataStr = parseInt(dataStr)
  }
  return moment(dataStr).format(pattern)
})

Vue.filter('showNum', function (val) {
  if (val > 1000000) {
    return ((val / 1000000).toFixed(0)) + 'M'
  } else if (val > 1000) {
    return ((val / 1000 + 1).toFixed(0)) + 'K'
  } else {
    return val
  }
})
