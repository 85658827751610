export default {
  root_path: process.env.VUE_APP_API_ROOT,
  minPayPtbNum: 1,
  unDataText: '没有数据......',
  channelLevel: 'q2',
  submenu: [],
  gameswitch: false,
  ShowPhonePage: 'bind',
  payswitch: false,
  channelswitch: false,
  DiscountSwitch: false,
  DiscountSwitchData: {},
  OpenSubSendDialog: false,
  ptbSendDialog: false,
  memPayDialog: false,
  moneyTip: false,
  payShow: true,
  getShow: false,
  TableLoading: true,
};
