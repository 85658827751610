<template>
  <!-- Video组件 -->
  <div id="common-video">
    <video
      ref="myVideo"
      :poster="poster"
      :src="src"
      :controls="controls"
      autoplay="autoplay"
      @timeupdate="timeupdate"
      controlslist="nodownload"
      class="video-box"
    ></video>
  </div>
</template>

<script>
export default {
  name: "CommonVideo",
  data() {
    return {
      videoState: true, // 视频播放状态
      // 学时
      studyTime: {
        currentTime: 0, // 当前已学时长
        duration: 0 // 总时长
      },
      timer: {}, // 定时器
      pauseTimer: {} // 暂停定时器
    };
  },
  /**
   * @param poster 展示图
   * @param src 视频资源
   * @param controls 是否显示控件
   */
  props: {
    poster: {
      type: String,
      required: false,
      default: ""
    },
    src: {
      type: String,
      required: true
    },
    controls: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mounted() {
    // 监听视频播放
    this.$refs.myVideo.addEventListener("play", () => {
      console.log("video is playing");
      this.openTimer();
    });
    // 监听视频暂停
    this.$refs.myVideo.addEventListener("pause", () => {
      console.log("video is stop");
      this.closeTimer();
    });
  },
  methods: {
    // 开启定时器
    openTimer() {
      this.timer = setInterval(() => {
        this.$emit("videoStudyTime", this.studyTime);
      }, 1000);
    },
    // 关闭定时器
    closeTimer() {
      clearInterval(this.timer);
      this.$emit("videoStudyTime", this.studyTime);
    },
    // 关闭暂停定时器
    closePauseTimer() {
      clearInterval(this.pauseTimer);
    },
    // 获取当前播放位置
    timeupdate(e) {
      this.studyTime.currentTime = e.target.currentTime;
      this.studyTime.duration = e.target.duration ? e.target.duration : 0;
    }
  },
  watch: {}
};
</script>

<style lang="scss">
#common-video {
  position: relative;
  .video-box {
    box-sizing: border-box;
    border: 0;
    display: block;
    width: 100%;
    height: 100%;
    outline: none !important;
  }
  .isShow {
    //进度条
    video::-webkit-media-controls-timeline {
      display: none;
    }
  }
  video::-webkit-media-controls-play-button {
    visibility: hidden;
  }
  .operate-btn {
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
  .operate-btn:hover {
    opacity: 0.8;
  }
  .fade-out {
    opacity: 0;
  }
}
</style>
