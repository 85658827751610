<template>
    <div id="app">
      <top-bar-v3/>
      <div :class="isMobile?'mobile-main':'mian'">
        <router-view/>
        <foot-bar-v3/>
      </div>
    </div>
  </template>

<script>
  import { mapGetters } from 'vuex'
  import { TopBarV3, FootBarV3 } from '@/components'

  export default {
    name: 'TopLayout',
    data () {
      return {}
    },
    components: {
      TopBarV3,
      FootBarV3
    },
    computed: {
      ...mapGetters(['isMobile','v3staticUrl']),
    },
    created () {},
  }
</script>

<style lang="scss">
  @import "@/assets/styles/indexV3.scss";
</style>
