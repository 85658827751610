<template>
  <div class="toast" v-show="show" v-if="!type">{{ message }}</div>
  <div :class="'info '+ type" v-else>
    <img v-if="type==='success'" :src="'https://static.zhuziplay.com/office/recharge/success.png'"/>
    <img v-if="type==='error'" :src="'https://static.zhuziplay.com/office/recharge/error.png'"/>
    <img v-if="type==='warning'" :src="'https://static.zhuziplay.com/office/recharge/warning.png'"/>
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'toast',
  data () {
    return {
      show: false,
      message: '',
      type: ''
    }
  }
}
</script>

<style lang="scss">
.toast {
  position: fixed;
  z-index: 20002;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #ECECEC;
  padding: 15px 20px;
  border-radius: 6px;
  background-color: #333333;
  font-size: 16px;
  text-align: center;
}
.info{
  position: fixed;
  top: 0px;
  z-index: 20002;
  font-size: 16px;
  display: flex;
  justify-content: center;
  width: 750px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 0px;
  img{
    height: 20px;
    padding: 3px;
  }
}
.success{
  background-color: #e1f3d8;
  color: #67c23a;
}
.error{
  background-color:#fde2e2;
  color: #f56c6c;
}
.warning{
  background-color: #faecd8;
  color: #e6a23c;
}
</style>
