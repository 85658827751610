<template>
  <div class="dialog_wrap" @click="closeDialog">
    <div class="dialog_wrap_bg" @click.stop="noTouch">
      <div class="dialog_wrap_close" @click="closeDialog"></div>
      <div class="dialog_wrap_title">Welcome to MASS</div>
      <div class="dialog_wrap_content">
        <img class="app-login-img" :src="codeInfo.qcode_url" />
      </div>
    </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getLoginQrcodeCreate, getLoginQrcodeCheck, getUserLogin } from '@/api/index'
import Vue from 'vue'

export default {
  name: 'WrapCodeLogin',
  computed: {
    ...mapGetters([
      'userInfo',
      'isMobile',
      'v3staticUrl',
    ])
  },
  data () {
    return {
      time: 10,
      isCheck: false,
      timer: '',
      codeInfo: {
        qcode: '',
        qcode_url: ''
      },
      loginInfo:{
        login_type: 1,
        code: '',
        token: ''
      },
      userData: {}
    }
  },
  watch: {
    time: function (newVal) {
      if (!this.isCheck) {
        this.checkQrcode()
      }
      if (newVal <= 0) {
        clearInterval(this.timer)
        this.timer = null
        //重新生产二维码
        this.getQrcode()
      }
    }
  },
  mounted () {
    
  },
  created () {
    this.getQrcode()
  },
  methods: {
    ...mapActions(['SetUserInfo']),
    noTouch () {

    },
    closeDialog () {
      this.$emit('onClose', true)
    },
    getQrcode () {
      getLoginQrcodeCreate().then(res => {
        this.codeInfo = res
        //创建定时器
        this.time = 10
        this.timer = setInterval(this.countTime, 3000)
      }).catch(error => {
        this.isCheck = true
        console.log(error)
      });
    },
    checkQrcode () {
      getLoginQrcodeCheck({ qcode: this.codeInfo.qcode }).then(res => {
        if (res.code === 999982) {
          this.isCheck = 1;
          this.getQrcode()
        }
        if(res.token){
          this.loginInfo.token = res.token
          this.loginInfo.code = res.code
          this.isCheck = 1;
          this.goLogin()
        }
      });
    },
    goLogin () {
      getUserLogin(this.loginInfo).then(res => {
        if (typeof(res.user_base) !== 'undefined') {
          this.userData = res.user_base
        }
        this.userData.token = res.token
        this.SetUserInfo(this.userData)
        //关闭定时器
        clearInterval(this.timer)
        this.timer = null
        //关闭二维码并提示登录成功
        this.$emit('onClose', true)
        Vue.prototype.showSuccess('Login Success!')
      });
    },
    countTime () {
      this.time--
      //console.log(this.time)
      if(this.time <= 0){
        clearInterval(this.timer)
        this.timer = null 
      }
    }
  }
}
</script>

<style lang="scss">

</style>
