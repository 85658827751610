<template>
    <div id="app">
      <new-top-bar/>
      <router-view/>
      <!-- <foot-bar-v3/> -->
    </div>
  </template>

<script>
  import { mapGetters } from 'vuex'
  import { NewTopBar, FootBarV3 } from '@/components'

  export default {
    name: 'NewLayout',
    data () {
      return {}
    },
    components: {
      NewTopBar,
      FootBarV3
    },
    computed: {
      ...mapGetters(['isMobile','v3staticUrl']),
    },
    created () {},
  }
</script>

<style lang="scss">
  @import "@/assets/styles/indexV3.scss";
</style>
