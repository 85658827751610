<template>
  <div class="dialog_wrap" @click="closeDialog">
    <div class="dialog_wrap_bg_short" @click.stop="noTouch">
      <div class="dialog_wrap_close" @click="closeDialog"></div>
      <div class="dialog_wrap_title">Logout to  MASS</div>
      <div class="dialog_wrap_content">
        <div class="content_logout" @click="goOut">LOGOUT</div>
      </div>
    </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'WrapLoginOut',
  computed: {
    ...mapGetters([
      'userInfo',
      'isMobile',
    ])
  },
  data () {
    return {}
  },
  created () {},
  methods: {
    ...mapActions(['UserOut']),
    noTouch () {

    },
    closeDialog () {
      this.$emit('onClose', true)
    },
    goOut() {
      this.UserOut().then((res) => {
        window.location.href = ('/')
      })
    }
  }
}
</script>

<style lang="scss">

</style>
