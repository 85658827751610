<template>
  <div :class="isMobile?'mobile-topbar':'topbar'">
    <div class="center" v-if="isMobile">
      <div class="center_img">
        <img src="@/assets/logo.png" alt="MASS" @click="addClass('home')">
      </div>
      <div class="center_ul" v-show="disabled">
        <ul>
          <li v-for="(item,index) in $t('topData.list')" :key="index" :class="{ active: currentActive === item.pathName }" @click="addClass(item.pathName)">
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="center_show">
        <a @click="hiddenClick" v-if="disabled">
          <img src="@/assets/images/icon_close.png" alt="MASS">
        </a>
        <a @click="showClick" v-else>
          <img src="@/assets/images/icon_menu.png" alt="MASS">
        </a>
      </div>
    </div>
    <div class="center"  v-else>
      <div class="center_img"><img src="@/assets/logo.png" alt="MASS"></div>
      <div class="center_ul">
        <ul>
          <li v-for="(item,index) in $t('topData.list')" :key="index">
            <a :href="item.path" :class="{ active: currentActive === item.pathName }" >{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <button type="button" @click="selectClick">{{ $t('topData.button.name') }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'topBar',
  computed: {
    ...mapGetters(['isMobile']),
  },
  data () {
    return {
      currentActive: 'home',
      mouseActive1: false,
      mouseActive2: false,
      disabled: 1,
    }
  },
  created () {
    var query = window.location.pathname
    if (query.length > 0) {
      var vars = query.split('/')
      if (vars[1].length === 0) {
        this.currentActive = 'home'
      }else{
        this.currentActive = vars[1]
      }
    }
  },
  methods: {
    showClick() {
      this.disabled = 1
      console.log(this.disabled)
    },
    hiddenClick() {
      this.disabled = 0
      console.log(this.disabled)
    },
    addClass (index) {
      this.currentActive = index
      this.$router.push({ name: index, query: {} })
    },
    selectStly () {
      this.mouseActive1 = true;
    },
    outStyle () {
      this.mouseActive1 = false;
    },
    selectStly2 () {
      this.mouseActive2 = true;
    },
    outStyle2 () {
      this.mouseActive2 = false;
    },
    selectClick (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss">

</style>
