<template>
  <div class="dialog_wrap" @click="closeDialog">
    <div class="dialog_wrap_bg" @click.stop="noTouch">
      <div class="dialog_wrap_close" @click="closeDialog"></div>
      <div class="dialog_wrap_title">Welcome to MASS</div>
      <div class="dialog_wrap_content" v-if="loginTpye===2">
        <img :src="v3staticUrl + 'wx_link.png'" class="app-login-img">
        <div class="login-type" @click="channgeType">Email Log</div>
      </div>
      <div class="dialog_wrap_content" v-else>
        <div class="form-input">
          <span><img :src="v3staticUrl + 'user.png'" class="form-input-img"></span>
          <input class="mobile-input" type="email" placeholder="Please enter your email address" v-model.trim="email" />
        </div>
        <div class="form-input">
          <span><img :src="v3staticUrl + 'password.png'" class="form-input-img"></span>
          <input class="code-input" type="tel" maxlength="6" placeholder="Please enter the verification code" v-model.trim="emailCode" />
          <div class="code-btn" @click="sendCode">
            {{ sendSec > 0 ? sendSec+'seconds again':'Send Code' }}
          </div>
        </div>
        <div class="login-checkbox">
          <input type="checkbox" value="" @change="checkboxChange">
          <a href="/privacy" target="_blank">Confirm your account</a>
        </div>
        <div class="btn-ok" @click="logIn">Log in</div>
        <div class="login-type" @click="channgeType">Scan Code</div>
      </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import md5 from 'md5';

export default {
  name: 'WrapLogin',
  computed: {
    ...mapGetters([
      'userInfo',
      'isMobile',
      'v3staticUrl'
    ])
  },
  data () {
    return {
      loginTpye: 1,
      sendSec: 0,
      isCheck: false,
      email: '',
      emailCode: ''
    }
  },
  created () {},
  methods: {
    ...mapActions(['UserOut','EmailCodeSend']),
    noTouch () {

    },
    closeDialog () {
      this.$emit('onClose', true)
    },
    channgeType () {
      if (this.loginTpye === 1) {
        this.loginTpye = 2
      } else {
        this.loginTpye = 1
      }
    },
    sendCode () {
      var regEmail = /^[A-Za-z0-9]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (!regEmail.test(this.email)) {
        this.showError('Please enter the correct email address')
        return;
      }
      if (this.sendSec > 0) {
        return;
      }
      this.sendSec = 60
      let timeId = setInterval(() => {
        this.sendSec--
        if (this.sendSec <= 0) {
          this.sendSec = 0
          clearInterval(timeId)
        }
      }, 6000)
      this.EmailCodeSend({ mobile: this.mobileNum }).then(res => {
        this.showSuccess('Verification code sent successfully!')
      }).catch(() => {
        this.showError('Verification code sending failed!')
      })
    },
    logIn () {
      if (this.email === '') {
        this.showError('Please enter an email, the email cannot be empty!')
        return
      }
      if (this.emailCode === '') {
        this.showError('Please enter the password, the verification code cannot be empty!')
        return
      } else {
        if(this.emailCode.length<6) {
          this.showError('Verification code with 6 digits, please re-enter!')
          return
        }
      }
    },
    checkboxChange (e) {
      this.isCheck = e.target.checked
    },
    goOut() {
      this.UserOut().then((res) => {
        window.location.href = ('/')
      })
    }
  }
}
</script>

<style lang="scss">

</style>
