import Vue from 'vue'
import ToastMessage from './index.vue'

let ToastConstructor = Vue.extend(ToastMessage)
let instance
let timer = null
let time = 3000
const Toast = function (options) {
  if (!instance) {
    instance = new ToastConstructor()
    instance.vm = instance.$mount()
    document.body.appendChild(instance.vm.$el)
  }
  if (timer) {
    clearTimeout(timer)
    timer = null
    instance.show = false
    instance.message = ''
    instance.type = ''
  }

  if (typeof options === 'string') {
    instance.message = options
  } else if (typeof options === 'object') {
    instance.message = options.message
    instance.type = options.type
    time = options.time || time
  } else {
    return
  }
  instance.show = true
  timer = setTimeout(() => {
    instance.show = false
    clearTimeout(timer)
    timer = null
    instance.message = ''
    instance.type = ''
  }, time)
}
window.toast = Toast
export default Toast
