<template>
  <div class="mobile-agreement">
    <div class="agreement_content">
      <div class="content_title">{{ $t('privacy.title') }}</div>
      <!-- <div class="content_time">{{ $t('privacy.time') }}</div> -->
      <div class="content_describe">
        <div v-html="$t('privacy.content')">
          {{ $t('privacy.content') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'privacy',
  data () {
    return {}
  },
  created () {},
  methods: {}
};
</script>

<style lang="scss">
  @import "@/assets/mobile/indexV3.scss";
  @import "@/assets/mobile/index.scss";
</style>
