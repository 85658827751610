<template>
  <div :class="isMobile?'mobile-newbar':'newbar'">
    <div :class="isMobile?'mobile-main-center':'main-center'">
      <img :src="v3staticUrl + 'logo.png'" class="center_img">
      <div class="center_ul">
        <ul>
          <li v-for="(item,index) in v3topData" :key="index">
            <a :href="item.path" :class="{ active: currentActive === item.pathName }" >{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <div class="center_userinfo" v-if="this.userInfo.token !== ''">
        <img class="userinfo_img" @click="showLoginOut" :src="this.userInfo.avatar" v-if="this.userInfo.avatar">
        <img class="userinfo_img" @click="showLoginOut" :src="v3staticUrl + 'user_img.png'" v-else>
      </div>
      <div class="center_userinfo" v-else>
        <div class="userinfo_button" @click="showLogin">LOGIN</div>
      </div>
    </div>
    <!-- <div class="main-right" v-if="!isMobile">
      <div class="main-right-img" v-if="this.userInfo.token === ''"><img :src="v3staticUrl + 'chongzhi.png'" @click="onLogin"></div>
      <div class="main-right-img"><img :src="v3staticUrl + 'chongzhi.png'" @click="onRecharge"></div>
    </div> -->
    <!-- <wrap-login @onClose='isShowLogin=false' v-if="isShowLogin"/> -->
    <wrap-code-login @onClose='isShowLogin=false' v-if="isShowLogin"/>
    <wrap-login-out @onClose='isShowOut=false' v-if="isShowOut"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { WrapCodeLogin, WrapLoginOut } from '@/components'

export default {
  name: 'NewTopBar',
  components: {
    WrapCodeLogin,
    WrapLoginOut
  },
  computed: {
    ...mapGetters(['isMobile', 'v3staticUrl', 'v3topData', 'userInfo']),
  },
  data () {
    return {
      currentActive: 'index',
      isShowLogin: false,
      isShowOut: false,
    }
  },
  created () {
    var query = window.location.pathname
    if (query.length > 0) {
      var vars = query.split('/')
      if (vars[1].length === 0) {
        this.currentActive = 'index'
      }else{
        this.currentActive = vars[1]
      }
    }
    if (this.userInfo.token !== '') {
      this.isShowLogin = false
    }
  },
  methods: {
    addClass (index) {
      this.currentActive = index
      this.$router.push({ name: index, query: {} })
    },
    onLogin () {
      this.isShowLogin = true
    },
    showLogin () {
      this.isShowLogin = true
    },
    showLoginOut () {
      this.isShowOut = true
    },
    onRecharge () {
      console.log(this.userInfo)
      if (this.userInfo.token === '') {
        this.isShowLogin = true
      } else {
        this.$router.push({ name: 'recharge', query: {} })
      }
    }
  }
}
</script>

<style lang="scss">

</style>
