import Vue from 'vue'
import storage from '@/utils/localStorage'
import utils from '@/utils/utils'

const isMobile = utils.isMobile()
const isIPhone = utils.isIPhone()

const state = {
  basicData: {
    title: '',
    logo: '',
    company: '',
    address: '',
    record_number: '',
    copyright: ''
  },
  userInfo: {
    nickname: '',
    age: '',
    gender: '',
    token: '',
    user_id: '',
    avatar: ''
  },
  staticUrl: window.staticUrl,
  v3staticUrl: window.v3staticUrl,
  language: window.language,
  isIPhone: isIPhone,
  isMobile: isMobile,
  downloadData: {
    androidUrl: 'https://play.google.com/store/apps/details?id=com.XJWL.SSmetaverse',
    appstoreUrl: 'https://apps.apple.com/ph/app/id1636852237',
    pcUrl: 'https://g1.xyxverse.com/website/download/1.0.1/MetaversePlay.rar',
    qrCodeUrl: '',
    playUrl: '',
    videoUrl: 'https://g1.xyxverse.com/website/mass-173703.mp4',
    buyUrl: 'https://mass-3d-create-play-1.xsollasitebuilder.com/'
  },
  v3topData:[
    {id:1,name:"Home",path:"/index",pathName:"index",list:[]},
    {id:2,name:"Product", path:"/product",pathName:"product",list:[]},
    {id:3,name:"Create",path:"/create",pathName:"create",list:[]},
    {id:4,name:"Latest News",path:"/news",pathName:"news",list:[]},
    {id:4,name:"Contact Us",path:"/contactUs",pathName:"contactUs",list:[]}
  ],
  followUs:[
    {
      url: "https://www.tiktok.com/@mass_createplay?_t=8cbFbWeG7wP&_r=1",
      img: window.v3staticUrl + "icon_tiktok.png"
    },
    {
      url: "https://www.instagram.com/mass3d_createplay/",
      img: window.v3staticUrl + "icon_ins.png"
    },
    {
      url: "https://www.youtube.com/@mass1967",
      img: window.v3staticUrl + "icon_youtube.png"
    },
    {
      url: "https://discord.gg/HcbrvSJ6NQ",
      img: window.v3staticUrl + "icon_discord.png"
    },
    {
      url: "https://www.facebook.com/groups/884971825842358",
      img: window.v3staticUrl + "icon_facebook.png"
    }
  ],
  v3footData:{
    copyright: {
      copyright:"© 2023 MASS TEAM All Rights Reserved",
      list: [
        { id:1, title:"Privacy Policy", url:"/privacy" },
        { id:2, title:"Terms of use", url:"/agreement" }
      ]
    },
    menuList:[
        {
          id:1,
          title:"Product",
          list: [{ id:1, title:"Creation", url:"/create" }]
        },
        {
            id:2,
            title:"Corporation",
            list: [{ id:1, title:"Contact Us", url:"/contactUs" }]
        },
        {
            id:3,
            title:"Creator",
            list: [ { id:11, title:"Tutorials", url:"/tutorials" }]
        }
    ]
  }
}

const getters = {
  staticUrl: state => state.staticUrl,
  v3staticUrl: state => state.v3staticUrl,
  basicData: state => state.basicData,
  language: state => state.language,
  isMobile: state => state.isMobile,
  isIPhone: state => state.isIPhone,
  downloadData: state => state.downloadData,
  v3topData: state => state.v3topData,
  followUs: state => state.followUs,
  v3footData: state => state.v3footData,
  userInfo: (state) => {
    if (state.userInfo.token === '') {
      let userInfo = storage.get('userInfo')
      if (userInfo !== null) {
        state.userInfo = JSON.parse(userInfo)
      }
    }
    return state.userInfo
  }
}

const mutations = {
  updateBasicData (state, params) {
    storage.set('basicData', JSON.stringify(params))
  },
  updateUserInfo (state, params) {
    storage.set('userInfo', JSON.stringify(params))
  },
}

const actions = {
  SetUserInfo ({ commit,state }, data) {
    console.log(state.userInfo)
    state.userInfo = data
    commit("updateUserInfo", data)
  },
  UserOut ({ commit }) {
    let userInfo = {
      username: '',
      mobile: '',
      email: '',
      token: '',
      userid: '',
    }
    commit('updateUserInfo', userInfo)
  },
  ComingSoon () {
    Vue.prototype.showError('Coming Soon ！')
    return
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
