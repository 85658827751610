<template>
  <div class="mobile-footbar" v-if="isMobile">
      <div class="link">
        <a v-for="(item,index) in $t('footData.right.link')" :key="index" :href="item.url" target="_brank">
          <img :src="staticUrl + item.img" :alt="item.title" >
        </a>
      </div>
      <ul>
        <li v-for="(item,index) in $t('footData.left.list')" :key="index"><a :href="item.url">{{ item.title }}</a></li>
      </ul>
      <p>{{ $t('footData.left.copyright') }}</p>
  </div>
  <div class="footbar"  v-else>
    <div class="center">
      <div class="left">
        <ul>
          <li v-for="(item,index) in $t('footData.left.list')" :key="index" >
            <a :href="item.url">{{ item.title }}</a>
          </li>
        </ul>
        <br/>
        <p>{{ $t('footData.left.copyright') }}</p>
      </div>
      <div class="right">
        <img v-for="(item,index) in $t('footData.right.link')" :key="index" :src="staticUrl + item.img" :alt="item.title" >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'footBar',
  computed: {
    ...mapGetters(['staticUrl', 'language', 'isMobile']),
  },
  methods: {
    ...mapActions(['getLanguage']),
  }
}
</script>

<style lang="scss">

</style>
